









import Vue from "vue";
import NavSideBar from "@/components/candidate/NavSideBar.vue";
import { SUBMIT_APP_ASSESSMENT_ANSWERS } from "@/store/modules/candidates/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { SocketService } from "@/services";
import { IApp } from "@/interfaces";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "CandidateWrapper",
  components: { NavSideBar },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  watch: {
    get_user_details() {
      this.reset();
    }
  },
  async created() {
    if (this.get_user_details) await this.reset();
  },
  methods: {
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    ...mapActions("candidate", {
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS
    }),
    async reset() {
      // If user refresh during inception assessment, cancel the assessment and display user a message
      const assessment_cancelled = localStorage.getItem("assessment_cancelled");
      if (assessment_cancelled && assessment_cancelled === "1") {
        const payload = localStorage.getItem("assessment");
        if (payload) {
          const data = JSON.parse(payload);
          SocketService.socket.emit(IApp.SocketEvents.CANCELLED_ASSESSMENT, {
            user_id: this.get_user_details.id,
            assessment_id: data.assessment_id
          });
          await this.submit_assessment_answers(data);
        }
        this.set_root_error("Your Assessment is cancelled");
        localStorage.removeItem("assessment_cancelled");
        localStorage.removeItem("assessment");
      }
    }
  }
});
